import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';

import save_icon from '../../../static/assets/icons/save_icon.svg';
import cross from '../../../static/assets/icons/cross_new.svg';

import { useTranslation } from 'react-i18next';
import {
  changeDrag, changeDragPhotos, fetchEventPicturesIfNeeded,
} from "../../../redux/actions/gallery";
import {toggleModalEnhancedRaw} from "../../../redux/actions/modals";

import useWindowWidth from "../../../utils/useWindowWidth/useWindowWidth";
import checkmark_material from "../../../static/assets/icons/checkmark_material.svg";
import {parentPostMessage} from "../../../utils/postMessage";

export const ActionInnerModal = ({actionRequest, closeModalEnhancedRaw, header, descriptionTextRows, cancelText, onText }) => {
  const { t } = useTranslation();
  const {isMobile} = useWindowWidth();

  return (
    <>
      <DescriptionHead>{header}</DescriptionHead>
      {descriptionTextRows.map(text => (
        <DescriptionText key={text}>
          {text}
        </DescriptionText>
      ))}
      <ButtonsContainer isMobile={isMobile}>
        <Button
          color={'dark'}
          backgroundColor={'white'}
          onClick={closeModalEnhancedRaw}
          hoverStyle={'border: 1px solid #000000;'}
        >
          {cancelText}
        </Button>
        <Button
          color={'white'}
          backgroundColor={'newDart'}
          onClick={actionRequest}
          hoverStyle={'background-color: #000000'}
        >
          {onText}
        </Button>
      </ButtonsContainer>
    </>
  );
}

export const SuccessModal = ({actionRequest }) => {
  const {isMobile} = useWindowWidth();

  return (
    <>
      <DescriptionHead>Erfolgreich gespeichert</DescriptionHead>
      <DescriptionText>
        Deine Sortierung wurde gespeichert.
      </DescriptionText>
      <ButtonsContainer isMobile={isMobile} one={true}>
        <Button
          color={'green'}
          backgroundColor={'green'}
          onClick={actionRequest}
          hoverStyle={'background-color: rgb(128, 180, 171)'}
        >
          <img src={checkmark_material} alt="checkmark" style={{height: '35px', margin: 'auto'}} />
        </Button>
      </ButtonsContainer>
    </>
  );
}

export const DragImagesActions = ({bottomPositionMobile, includeOpacity}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeEvent = useSelector(state => state.activeEvent);
  const currentUser = useSelector(state => state.currentUser);
  const {drag_photos} = useSelector(state => state.dragReducer);

  const isOwner = currentUser.id == activeEvent.evt_owner_id;

  const discardSelect = () => {


    toggleModalEnhancedRaw(dispatch, {
      children: (
        <ActionInnerModal
          actionRequest={async () => {
            dispatch(changeDrag(false))

            toggleModalEnhancedRaw(dispatch, false)
            parentPostMessage('dispatch_gallery_sort_mode', false, true, false, true, false);
          }}
          closeModalEnhancedRaw={() => toggleModalEnhancedRaw(dispatch, false)}
          header={'Sortierung zurücksetzen'}
          descriptionTextRows={[`Deine Änderungen werden nicht gespeichert.`, `Du gelangst zurück zum Fotoalbum.`]}
          cancelText={t('cancel')}
          onText={'Zurücksetzen'}
        />
      )
    })
  }

  const saveDragOption = async () => {

    const data = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}api/event/update_order`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventID: activeEvent.id,
        photos: drag_photos?.map(photo => ({id: photo.id, order: photo.order}))
      })
    }).then(response => response.json()).then(data => {
      if (data && data.success) {

        dispatch(fetchEventPicturesIfNeeded(activeEvent.id, false, true, (pictures) => {
          dispatch(changeDrag(false))
          dispatch(changeDragPhotos([]));
          parentPostMessage('dispatch_gallery_sort_mode', false, true, false, true, false);
          toggleModalEnhancedRaw(dispatch, {
            children: (
              <SuccessModal
                actionRequest={async () => {

                  toggleModalEnhancedRaw(dispatch, false)
                }}
              />
            )
          })
        }, true))
      }

    }).catch(err => console.log(err))
  }

  return (
    <Wrapper bottomPositionMobile={bottomPositionMobile} includeOpacity={includeOpacity} className={!!bottomPositionMobile && 'in-view'}>

      <div />

      <div style={{display: 'flex'}}>

        <ActionButton uploading={0} onClick={ async () => {
          await saveDragOption()
        }}>
          <UploadImageWrapper bg={true} width={'40px'}>
            <ActionImage src={save_icon} height={33} alt="save" draggable="false"/>
          </UploadImageWrapper>
        </ActionButton>


        <ActionButton onClick={() => discardSelect()}>
          <UploadImageWrapper bg={true} width={'40px'}>
            <ActionImage src={cross} height={34} alt="cancel" draggable="false"/>
          </UploadImageWrapper>
        </ActionButton>
      </div>
    </Wrapper>
  );
};


const Wrapper = styled.label`
  margin: auto;
  cursor: pointer;
  padding: 10px;
  //border-radius: 50%;
  bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: #ffffff;
  ${({ theme, bottomPositionMobile, includeOpacity }) => {

  return theme.onMD(`
    &.in-view {
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }
    width: 100%;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: ${bottomPositionMobile || 0}px;
    transition: none;
    opacity: ${includeOpacity ? 0 : 1};
    border-radius: 0;
    margin: 0;
    z-index: 3;
    background-color: #ffffff;
  `)
}}
`;

const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 48px;
  text-align: left;
  //font: normal normal normal 14px/19px Roboto;
  font: normal normal normal 16px/21px Roboto;
  font-family: ${({theme}) => theme.font.default};
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  cursor: pointer;
  border-radius: 24px;
  &:hover {
    img {
      transform: scale(1.12, 1.12);
    }

    .upload-text::after {
      transform: scaleX(1);
      transform-origin: center;
    }
  }
`;

/**
 * Image for upload button
 * */

const UploadImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({width}) => width ? width : 'auto'};
  height: 40px;
  border-radius: 20px;
  background-color: ${({bg}) => bg ? '#F7F3ED' : 'none'} ;
  box-shadow: 0 0 0 10px #ffffff;
  margin: 10px 8px 10px 10px;
`;

const ActionImage = styled.img`
  //margin-bottom: -3px;
  height: ${({height}) => height || 24}px;
  user-select: none;
  cursor: pointer;
  transform: scale(1, 1);
  transform-origin: center;
  transition: transform 0.25s;
`;

/**
 * Loader for upload button
 * */

const DescriptionHead = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: ${({theme}) => theme.font.default};
  color: ${({theme}) => theme.palette.dark};
  ${({theme}) =>
  theme.onMD(`
    margin: 35px 0 25px !important;
    font-size: 18px;
  `)}
`;

const DescriptionText = styled.div`
  width: 100%;
  text-align: center;
  font: normal normal 300 16px/25px Roboto;
  font-family: ${({theme}) => theme.font.default};
`;


const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({one}) => one ? 'center' : 'space-between'};
  flex-direction: ${({isMobile}) => isMobile ? 'column': 'row'};
  width: 355px;
  margin: 35px auto 0 auto;
  ${({theme}) =>
  theme.onMD(`
      width: 100%;
      height: 111px;
  `)}
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 160px;
  height: 38px;
  line-height: 36px;
  overflow: hidden;
  vertical-align: center;
  border: 1px solid #D1D3D4;
  border-radius: 19px;
  font-size: 14px ;
  font-family: ${({theme}) => theme.font.default};
  color: ${({ color, theme: {palette}}) => palette[color]};
  background-color: ${({ backgroundColor, theme: {palette} }) => palette[backgroundColor]};
  cursor: pointer;
  &:hover {
    ${({hoverStyle}) => hoverStyle};
  }
  
  ${({theme}) =>
  theme.onMD(`
      width: 100%;
      height: 48px;
      line-height: 46px;
      border-radius: 24px;
  `)}
`;
